import React from "react"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const Card = ({ item }) => {
  if (item.image === undefined || item.image.filename === "") {
    item.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }

  return (
    <Link className="block w-full h-full shadow-md group" link={item.link}>
      <div className="flex flex-col justify-between h-full">
        <div className="h-full">
          {item.image && (
            <div>
              <Image
                image={item.image}
                fitIn={item.image_fitin}
                aspectRatio="16by9"
              />
            </div>
          )}
          <div className="px-grid">
            <HeadlineEnhanced
              kickerClass="bg-primary text-white -mx-grid px-grid"
              kicker={item.kicker}
              headline={item.headline}
              headlineLevel="h4"
              headlineClass="group-hover:text-primary"
            />
            {item.text?.length > 0 && <p className="my-4 prose">{item.text}</p>}
          </div>
        </div>
        {item.text?.length > 0 && (
          <div className="px-4 pb-4">
            <Link className="inline-block text-primary group-hover:text-primary-hover">
              {item.link_text}
            </Link>
          </div>
        )}
      </div>
    </Link>
  )
}

export default Card
